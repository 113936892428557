.alert.info {
  --alert-colour: var(--highlight);
}

.alert.success {
  --alert-colour: var(--highlight-green);
}

.alert.error {
  --alert-colour: var(--highlight-red);
}

.alert.warning {
  --alert-colour: #ff9800;
}

.alert {
  display: flex;
  background-color: #fff;
  border: 2px solid var(--alert-colour);
  border-radius: 4px;
  color: var(--text-primary);
  margin: 1rem 0;
  padding: 0.75rem;
}

.icon {
  color: var(--alert-colour);
  margin-right: 1rem;
}

.content {
  align-self: center;
}
