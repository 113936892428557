.container {
  border: 1px solid var(--text-primary-light);
  border-radius: 4px;
  display: flex;
  flex: 1;
}

.line_numbers {
  border-right: 1px solid var(--text-primary-light);
  font-family: 'Consolas', 'Courier New', Courier, monospace;
  font-size: 0.75rem;
  padding: 0.5rem 0.25rem;
}

.textarea {
  border: 0;
  flex: 1;
  font-family: 'Consolas', 'Courier New', Courier, monospace;
  font-size: 0.75rem;
  padding: 0.5rem;
  outline: none;
  resize: none;
}
