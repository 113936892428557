.container {
  background: #fff;
  border: 1px solid var(--text-primary-light);
  border-radius: 4px;
  display: flex;
  flex: 1;
}

.text_input {
  background: transparent;
  border: 0;
  color: var(--text-primary-dark);
  font-size: 1rem;
  outline: 0;
  padding: 0.5rem;
  width: 98%;
}

.disabled {
  background-color: #ccc;
}

.prefix {
  background: #eee;
  border-right: 1px solid var(--text-primary-light);
  color: #aaa;
  padding: 0.5rem;
}
