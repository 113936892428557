.button_group {
}

.button_group button {
  background-color: transparent;
  border: 1px solid var(--border-secondary);
  border-left: 0;
  color: var(--border-secondary);
  cursor: pointer;
  font-size: 1rem;
  outline: 0;
  padding: 0.35rem 1.25rem;
}

.button_group button:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 1px solid var(--border-secondary);
}

.button_group button:last-child {
  border-radius: 0 4px 4px 0;
}

.button_group button.selected {
  background-color: var(--highlight);
  color: var(--text-primary-light);
}
