* {
  margin: 0;
  padding: 0;
}

:root {
  /* Branding */
  --page-background: #f5f5f5;
  --text-primary-dark: #191919;
  --text-primary-light: #e4e4e4;
  --text-primary: #191919;
  --text-secondary: #8e8e8e;
  --highlight: #0081a7;
  --highlight-green: #00a878;
  --highlight-red: #e63946;
  --brand: #f46036;
  --primary-dark: #01172f;

  --border-primary: #ccc;
  --border-secondary: #01172f;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

html {
  height: 100%;
}

body,
#root {
  background: var(--page-background);
  display: flex;
  min-height: 100%;
  max-height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  background: var(--page-background);
  width: 100vw;
}

@media only screen and (max-device-width: 768px) {
  .container {
    flex-direction: column;
  }
}
