.para {
  display: block;
  margin: 1rem 0;
}

.primary_dark {
  color: var(--text-primary);
}

.primary_light {
  color: var(--text-primary-light);
}

.secondary {
  color: var(--text-secondary);
}

.small {
  font-size: 0.85rem;
}

.normal {
  font-size: 1rem;
}

.large {
  font-size: 1.25rem;
}
