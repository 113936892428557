.page_content {
  background: #ccc;
  background-image: url(/images/fatpaws_background.jpg);
  background-size: cover;
  padding: 2rem;
  width: 100%;
}

.login_card {
  backdrop-filter: saturate(180%) blur(10px);
  background: #01172f7e;
  border: 2px solid var(--brand);
  border-radius: 6px;
  box-shadow: 0px 0px 10px 2px #666;
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 10rem;
  padding: 2rem;
  width: 50%;
}

.logotype {
  max-height: 2.5rem;
  object-fit: scale-down;
}

.logo {
  max-height: 3rem;
  object-fit: scale-down;
}

.login_content {
  margin: 2rem 0;
}

@media only screen and (max-device-width: 768px) {
  .page_content {
    height: 100%;
    width: auto;
  }

  .login_card {
    width: 75%;
  }
}
