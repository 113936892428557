@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
}

.spinner.inline {
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}

.spinner.dark:before {
  border-top: 2px solid #fff;
}

.spinner.button {
  display: inline-block;
  height: 1.25rem;
  margin-left: 1.5rem;
  vertical-align: bottom;
}
