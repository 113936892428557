.button.light {
  --button-colour: var(--border-secondary);
}

.button.dark {
  --button-colour: var(--highlight);
}

.button {
  background-color: transparent;
  border: 1px solid var(--button-colour);
  border-radius: 4px;
  color: var(--button-colour);
  cursor: pointer;
  font-size: 1rem;
  padding: 0.35rem 1.25rem;
}

.button:hover {
  border-color: var(--brand);
}

.primary {
  background-color: var(--button-colour);
  color: #fff;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.button.disabled:hover {
  border-color: initial;
}
